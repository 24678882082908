import React from 'react'
import { Card } from 'react-bootstrap'

const TripFormNotes = () => (
  <Card.Body>
    <Card.Title>Trip Notes</Card.Title>
  </Card.Body>
)

export default TripFormNotes
