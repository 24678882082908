export const FIELD_NAMES = {
  NAME: 'name',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  TRAVEL_PARTNERS: 'travelPartners',
  CATEGORIES: 'categories',
  COUNTRIES: 'countries',
  PLACES: 'places',
  PHOTOS: 'photos',
}
